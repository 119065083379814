/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useCallback, useMemo, useRef, useState } from 'react';
import { ApplicationLocation } from '../../models/ApplicationLocation';
import Tooltip from '../shared/Tooltip';
import Map, { Popup, Layer, LayerProps, Source, MapRef, MapLayerMouseEvent } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl, { GeoJSONSource } from 'mapbox-gl';
import { colourRgb } from '../../utils/ColourUtils';

mapboxgl.accessToken = import.meta.env.VITE_MAPBOX_KEY as string;

const clusterLayer: LayerProps = {
  id: 'clusters',
  type: 'circle',
  source: 'data_points',
  filter: ['has', 'point_count'],
  paint: {
    'circle-color': colourRgb('dashboard-2'),
    'circle-radius': 16,
    'circle-stroke-width': 2,
    'circle-stroke-color': '#fff',
  },
};

const clusterCountLayer: LayerProps = {
  id: 'cluster-count',
  type: 'symbol',
  source: 'data_points',
  filter: ['has', 'point_count'],
  layout: {
    'text-field': '{point_count_abbreviated}',
    'text-font': ['Open Sans Regular', 'Arial Unicode MS Regular'],
    'text-size': 12,
  },
  paint: {
    'text-color': '#fff',
  },
};

const unclusteredPointLayer: LayerProps = {
  id: 'unclustered-point',
  type: 'circle',
  source: 'data_points',
  filter: ['!', ['has', 'point_count']],
  paint: {
    'circle-color': colourRgb('primary-2'),
    'circle-radius': 16,
    'circle-stroke-width': 2,
    'circle-stroke-color': '#fff',
  },
};

const unclusteredPointSymbolLayer: LayerProps = {
  id: 'unclustered-point-symbol',
  type: 'symbol',
  source: 'data_points',
  filter: ['!', ['has', 'point_count']],
  layout: {
    'text-field': '{form_initials}',
    'text-font': ['Open Sans Regular', 'Arial Unicode MS Regular'],
    'text-size': 12,
  },
  paint: {
    'text-color': '#fff',
    'icon-opacity': 1,
  },
};

const initialMapState = {
  zoom: 0.5,
  latitude: 0,
  longitude: 0,
};

const SOURCE_ID = 'data_points';

type WorldMapProps = {
  data: ApplicationLocation[];
};

const WorldMap: FC<WorldMapProps> = (props) => {
  const { data: applicationLocations } = props;

  const [cursor, setCursor] = useState<string>('grab');
  const [popup, setPopup] = useState<any>(null);
  const [tooltip, setTooltip] = useState<any>('');

  const mapRef = useRef<MapRef>(null);
  const tooltipRef = useRef<HTMLDivElement | null>(null);

  const mapStyle = {
    height: '100%',
    width: '100%',
  };

  const onClick = useCallback((e: MapLayerMouseEvent) => {
    const clusterId = e.features?.filter((x) => x.properties?.cluster).map((x) => x.id as number)[0];
    if (!clusterId) {
      setPopup(null);
      return;
    }

    const source = e.target.getSource(SOURCE_ID) as GeoJSONSource;
    source.getClusterLeaves(clusterId, -1, 0, (_, leaves) => {
      if (!leaves.length) {
        return;
      }

      setPopup({
        coordinates: (leaves[0].geometry as any).coordinates,
        total: leaves.length,
        leaves: leaves.slice(0, 3),
      });
    });
  }, []);

  const onMouseDown = useCallback(() => {
    setCursor('grabbing');
  }, []);

  const onMouseUp = useCallback(() => {
    setCursor('grab');
  }, []);

  const onMouseEnter = useCallback(() => {
    tooltipRef.current?.dispatchEvent(new MouseEvent('mouseenter'));
  }, []);

  const onMouseLeave = useCallback(() => {
    tooltipRef.current?.dispatchEvent(new MouseEvent('mouseleave'));
  }, []);

  const onMouseMove = useCallback((e: MapLayerMouseEvent) => {
    setCursor(e.features?.some((x) => x.properties?.cluster) ? 'pointer' : 'grab');

    const poi = e.features?.find((x) => x.geometry.type === 'Point' && !x.properties?.cluster);
    if (!poi) {
      setTooltip('');
      return;
    }

    setTooltip(
      <div className="min-w-32 max-w-sm">
        <div className="font-medium">{poi.properties?.subtitle}</div>
        <div>{poi.properties?.title}</div>
        <div className="h-1 border-t border-gray-300"></div>
        <div>{poi.properties?.address}</div>
      </div>,
    );
  }, []);

  const sourceData = useMemo(() => {
    const result: GeoJSON.FeatureCollection<GeoJSON.Geometry> = {
      type: 'FeatureCollection',
      features: [],
    };

    for (const point of applicationLocations) {
      result.features.push({
        type: 'Feature',
        properties: {
          title: point.title,
          subtitle: point.subtitle,
          icon: point.iconCode,
          address: point.address,
          form_initials: point.subtitle.substring(0, 2).toUpperCase(),
        },
        geometry: {
          type: 'Point',
          coordinates: [point.lng, point.lat],
        },
      });
    }

    return result;
  }, [applicationLocations]);

  return (
    <>
      <Tooltip text={tooltip}>
        {(tooltip) => {
          (tooltip.ref as any)(tooltipRef);
          return <div {...tooltip} ref={tooltipRef} />;
        }}
      </Tooltip>
      <Map
        mapStyle="mapbox://styles/playbook/clgdfz3sd00gz01p0w5izdwcs"
        initialViewState={initialMapState}
        style={mapStyle}
        onClick={onClick}
        onMouseMove={onMouseMove}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        cursor={cursor}
        projection={{ name: 'equirectangular' }}
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        interactiveLayerIds={[clusterLayer.id!, clusterCountLayer.id!, unclusteredPointLayer.id!, unclusteredPointSymbolLayer.id!]}
        ref={mapRef}
        attributionControl={false}
      >
        <Source id={SOURCE_ID} type="geojson" data={sourceData} cluster={true} clusterMaxZoom={14} clusterRadius={60}>
          <Layer {...clusterLayer} />
          <Layer {...clusterCountLayer} />
          <Layer {...unclusteredPointLayer} />
          <Layer {...unclusteredPointSymbolLayer} />
        </Source>

        {popup && (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore - Popup doesn't define 'children' prop -> bug
          <Popup longitude={popup.coordinates[0]} latitude={popup.coordinates[1]} closeButton={false}>
            <div className="text-dpm-12 rounded-sm bg-white">
              {popup.leaves.map((leaf: any, index: number) => (
                <div key={index}>
                  <div className="p-2">
                    <div className="font-medium">{leaf.properties.subtitle}</div>
                    <div>{leaf.properties.title}</div>
                  </div>
                  {index < popup.leaves.length - 1 && <div className="h-1 border-t border-gray-300"></div>}
                </div>
              ))}
              {popup.total > popup.leaves.length ? <div className="border-t border-gray-300 pt-2 text-center">And more...</div> : null}
            </div>
          </Popup>
        )}
      </Map>
    </>
  );
};

export default WorldMap;
